<template>
  <nav v-if="Available" class="navbar">
    <div class="links">
      <img src="../assets/icon.gif" />
      <img src="../assets/svg/web.svg" />
      <img src="../assets/svg/grafica.svg" />
    </div>
    <div v-if="$root.state.User.IsAdmin" class="actions">
      <button @click="selectCommerciale(-1)" :class="$root.activeManager === null ? 'active' : ''">
        <img src="../assets/idea.svg" />
      </button>
      <button @click="selectCommerciale(3)" :class="$root.activeManager === 3 ? 'active' : ''">
        <img src="../assets/img/commerciale_3.jpg" />
      </button>
      <button @click="selectCommerciale(2)" :class="$root.activeManager === 2 ? 'active' : ''">
        <img src="../assets/ivan.png" />
      </button>
      <button @click="selectCommerciale(1)" :class="$root.activeManager === 1 ? 'active' : ''">
        <img src="../assets/matteo.png" />
      </button>

      <router-link to="/" custom v-slot="{ href, navigate, isActive }">
        <NavLink :active="isActive" :href="href" @click="navigate">Home</NavLink>
      </router-link>
      <router-link to="/fornitori" custom v-slot="{ href, navigate, isActive }">
        <NavLink :active="isActive" :href="href" @click="navigate">Fornitori</NavLink>
      </router-link>
      <router-link to="/clients" custom v-slot="{ href, navigate, isActive }">
        <NavLink :active="isActive" :href="href" @click="navigate">Clienti</NavLink>
      </router-link>
    </div>
    <div class="buttons">
      <button v-if="$root.state.User.IsAdmin" v-on:click="openClientForm">
        <img src="../assets/svg/add.svg" />
      </button>
      <button v-on:click="logout">
        <img src="../assets/svg/logout.svg" />
      </button>
    </div>
  </nav>
</template>

<script>
  export default {
    name: "Navbar",

    data() {
      return {
        Available: false,
      };
    },

    methods: {
      logout: function() {
        sessionStorage.removeItem("token");
        window.location.reload();
      },

      openClientForm: function() {
        this.$root.state.Client.Open = true;
      },

      selectCommerciale: function(v) {
        this.$root.activeManager = v===-1? null : v;
        this.$root.state.SelectedCommercial = v;
      },
    },

    watch: {
      $route: function(to) {
        this.Available = to.path !== "/";
      },
    },
  };
</script>

<style>
  .navbar .actions>button {
    border: solid 2px #222;
    border-radius: 48px;
    padding: 4px;
    width: 48px;
    height: 48px;
    transition: all .25s ease;
  }
  .navbar .actions>button.active {
    border-color:#2a9aff;
    background-color:#223445;
  }

  .navbar .actions>button>img {
    border-radius: 48px;
    opacity: 0.5;
    transition: opacity .25s ease;
    width:100%;
    height:100%;
    padding:0;
  }
  .navbar .actions>button.active>img {
    border-radius:48px;
    opacity: 1;
  }

  @media screen and (max-width: 712px) {


    .navbar .buttons button,
    .navbar .actions button {
      background-color: #1276dc;
      padding: 0 !important;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
    }



    .navbar .actions button {
      background-color: black;
    }

    .navbar .actions img {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
</style>
