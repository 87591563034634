<template>
  <div class="login-container">
    <img src="../assets/logo.gif" />
    <form @submit="login">
      <div>
        <input name="user" v-model="user" :class="hasError" type="text" />
      </div>
      <div>
        <input name="pass" v-model="pass" :class="hasError" type="password" />
      </div>
      <div>
        <input type="submit" value="Login" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { HOSTNAME } from "../api/requests";

export default {
  name: "Login",
  data() {
    return {
      hasError: String,
      user: "",
      pass: "",
    };
  },

  methods: {
    login: function(e) {
      e.preventDefault();
      axios
        .get(HOSTNAME + "/api/auth/", {
          headers: {
            Username: e.target.user.value,
            Password: e.target.pass.value,
          },
        })
        .then((res) => {
          this.hasError = "";
          sessionStorage.setItem("token", res.data.Token);
          this.$root.redirect(res.data.IsAdmin);
        })
        .catch((err) => {
          console.log(err);
          this.hasError = err.response.status === 403 ? "err-input" : "";
        });
    },
  },
};
</script>

<style>
.login-container {
  background-color: #090a0f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  height: 100vh;
}

.login-container img {
  width: 400px;
  height: auto;
}

.login-container form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-container .err-input {
  border-color: red;
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 0, 0, 0.2);
}

.login-container input {
  background-color: #062134;
}

.login-container input[type="submit"] {
  background-color: #0f3e60;
  color: white;
  width: 100%;
}
</style>