<template>
    <div class="utils-buttons">
        <button :class="this.$root.state.taskPanel === 'done' ? 'active' : ''" v-on:click="setTask('done')">DONE</button>
        <div>
            <span class="notifica">{{count}}</span>

            <button :class="this.$root.state.taskPanel === 'todo' ? 'active' : ''" v-on:click="setTask('todo')">TO DO</button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { HOSTNAME } from "../api/requests";

export default {
    name: "Switcher",

    data() {
        return {
            count: 0
        }
    },

    mounted() {
        this.getCount()
    },

    methods: {
        setTask(v) {
            this.$root.state.taskPanel = v
        },

        getCount() {
            axios
                .get(HOSTNAME + "/api/staff/work/all/count/", {
                    headers: {
                        Token: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    this.count = res.data.count;
                });
        }
    }
}
</script>