<template>
    <div v-if="Available" class="utilities">
        <div class="top" style="flex-direction: row-reverse">
            <div class="item">
                <User />
            </div>
            <Searchbar />
        </div>
        <div class="top">
            <div class="item">
                <Switcher />
            </div>
            <div class="item">
                <Date />
            </div>
            <div class="item">
                <Export />
            </div>
        </div>
    </div>
</template>

<script>
import Searchbar from './Searchbox.vue'
import Date from './Date.vue'
import User from './User.vue'
import Switcher from './Switcher.vue'
import Export from './Export.vue'

export default {
    name: "Utilities",
    components: {
    Searchbar,
    Date,
    User,
    Switcher,
    Export
},

    data() {
        return {
            Available: false
        }
    },

    watch: {
        $route: function(to) {
            this.Available = to.path !== '/';
        }
    }
}
</script>

<style>

.utilities .top {
    position:relative;
    display: flex;
    flex-direction: row;
    margin: 28px 0;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 712px) {
    .utilities .top {
        margin: 12px 0;
    }

    .utilities .top .ignored {
        display: none;
    }
}

</style>