<template>
    <div class="user-profile">
        <span class="name-surname" v-if="Nome !== null && Surname !== null">
            <span>{{ Nome }} </span> <span class='surname'>{{ Surname }}</span>
        </span>
        <span v-else-if="Username !== null"><span class="username">{{ Username }}</span></span>
        <img v-else src={{Profile}}>
        <Profile :Name="this.Nome" :Surname="this.Surname" :Username="this.Username" :Profile="this.Profile" />
    </div>
</template>

<script>
import Profile from './Profile.vue';

export default {
    name: "User",

    components: {
        Profile
    },

    computed: {

        Nome: function () {
            console.log("User->Nome: " + this.$root.state.User.Name)
            return this.$root.state.User.Name;
        },

        Surname: function () {
            console.log("User->Surname: " + this.$root.state.User.Surname)
            return this.$root.state.User.Surname;
        },

        Username: function () {
            return this.$root.state.User.Username;
        },

        Profile: function () {
            return this.$root.state.User.Profile;
        },
    },

    methods: {
        getinitials: function (Name, Surname, Username) {
            console.log("User->getinitials")
            console.log([Name, Surname]);
            if (Name !== undefined && Surname !== undefined)
                return Name[0] + Surname[0];
            else
                return Username[0];
        }
    },
}

</script>

<style>
.user-profile {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 18px;
}
</style>