<template>
    <div v-if="$root.state.User.IsAdmin" class="utils-buttons">
        <button v-on:click="this.export">Esporta</button>
    </div>
</template>

<script>
    import { HOSTNAME } from "../api/requests";


    export default {
        name: "ExportBtn",

        computed: {
            actualDate: function() {
                return this.$root.state.ActualDate;
            },

            selectedCommercial: function() {
                return this.$root.state.SelectedCommercial;
            },
        },

        methods: {
            export: function() {
                window.open(HOSTNAME + `/api/clients/export?Token=${sessionStorage.getItem("token")}&Filter=${this.actualDate.toISOString()}&Commercial=${this.selectedCommercial}`)
            }
        },
    };
</script>