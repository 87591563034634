<template>
    <div class="search-box">
        <input type="search" v-model="this.$root.state.SearchContent" />
        <button><img src="../assets/svg/search.svg"></button>
    </div>
</template>

<script>
export default {
    name: "Searchbox"
}
</script>

<style>
.search-box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
}

.search-box button {
    transform: translateX(-40px);
}

@media screen and (max-width: 712px) {
    .search-box {
        position: initial;
        transform: initial;
    }

    .search-box input {
        width: 100%;
        min-width: 0px !important;
    }
}

</style>