<template>
    <div class="date-container">
        <button v-on:click="removeMonth"><img src="../assets/svg/left.svg"></button>
        <span :class="itsNow">{{getMonth(this.$root.state.ActualDate.getMonth()) + " " + this.$root.state.ActualDate.getFullYear()}}</span>
        <button v-on:click="addMonth"><img src="../assets/svg/right.svg"></button>
    </div>
</template>

<script>

export default {
    name: "Date",

    data() {
        return {
            itsNow: ""
        }
    },

    mounted() {
            this.checkDate()
    },

    methods: {
        getMonth: function (month) {
            let months = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre"
            ]

            return months[month]
        },

        addMonth: function() {
            let dt = this.$root.state.ActualDate;
            this.$root.state.ActualDate = new Date(dt.setMonth(dt.getMonth() + 1))
            this.checkDate()
        },

        removeMonth: function() {
            let dt = this.$root.state.ActualDate;
            this.$root.state.ActualDate = new Date(dt.setMonth(dt.getMonth() - 1))
            this.checkDate()
        },

        checkDate: function() {
            this.itsNow = this.isEqual(this.$root.state.ActualDate, new Date()) ? "date-now" : ""
        },

        isEqual: function(date1, date2) {
            return date1.getMonth() === date2.getMonth() &&
                   date1.getFullYear() === date2.getFullYear()
        }
    }
}
</script>

<style>
.date-container {
    position: absolute;
    top:0;
    left:50%;
    transform:translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    text-align: center;
}

.date-container button {
    width: 36px;
    height: 36px;
    padding:0;
    background:#121a22;
    border-radius: 16px;
}
.date-container button > img {
    width: 100%;
    height: auto;
}

.date-container span {
    width: 140px;
    color: #e8ebef;
}

/* selettore maledetto */
.date-container img {
    width: 32px;
    height: auto;
}

.date-container span.date-now {
    font-weight: bold;
    color:#fff;
}
</style>
